import React, {
  forwardRef,
  RefObject,
  useImperativeHandle,
  useRef,
} from 'react';
import { Map as GoogleMap, IMapProps, GoogleAPI } from 'google-maps-react';
import { Easing, Tween, update } from '@tweenjs/tween.js';
import { getBoundsZoomLevel } from '../../utils/map';

interface CameraRef {
  animateTo: (cameraOptions: google.maps.CameraOptions) => void;
  animateToBounds: (bounds: google.maps.LatLngBounds) => void;
}

export interface MapTest {
  test: MapRef | null;
  camera: CameraRef | null;
}

export type MapRef = GoogleMap & { map: google.maps.Map };

interface MapProps {
  google: GoogleAPI;
  ref: RefObject<MapRef>;
  children?: React.ReactNode;
  initialCenter?: google.maps.LatLngLiteral;
  initialZoom?: number;
  onReady?: IMapProps['onReady'];
}

function animate(time: number) {
  requestAnimationFrame(animate);
  update(time);
}

const Map = forwardRef<MapTest, MapProps>(
  ({ google, initialCenter, initialZoom, onReady, children }, ref) => {
    const mapRef = useRef<GoogleMap & { map: google.maps.Map }>(null);

    const animateToBounds = (bounds: google.maps.LatLngBounds) => {
      if (!mapRef?.current) {
        return;
      }

      const dimensions = {
        height: mapRef.current.map.getDiv().offsetHeight,
        width: mapRef.current.map.getDiv().offsetWidth,
      };

      animateTo({
        heading: 0,
        tilt: 0,
        zoom: getBoundsZoomLevel(bounds, dimensions),
        center: {
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng(),
        },
      });
    };

    const animateTo = (cameraOptions: google.maps.CameraOptions) => {
      if (!mapRef?.current) {
        return;
      }

      const currentOptions = {
        tilt: 0,
        heading: 0,
        zoom: mapRef.current.map.getZoom(),
        center: {
          lat: mapRef.current.map.getCenter()?.lat() || 0,
          lng: mapRef.current.map.getCenter()?.lng() || 0,
        },
      };

      if (cameraOptions.zoom && cameraOptions.zoom > 15) {
        cameraOptions.zoom = 15;
      }

      // const middleBounds = new google.maps.LatLngBounds(cameraOptions.center, currentOptions.center);
      // const middleZoom = getBoundsZoomLevel(middleBounds, {
      //   width: mapRef.current.map.getDiv().offsetWidth,
      //   height: mapRef.current.map.getDiv().offsetHeight,
      // });

      new Tween(currentOptions)
        .to(cameraOptions, 2000)
        .easing(Easing.Cubic.InOut)
        .onUpdate(() => {
          mapRef.current?.map.moveCamera(currentOptions);
        })
        .start();

      requestAnimationFrame(animate);
    };

    useImperativeHandle(ref, () => ({
      get test() {
        return mapRef.current;
      },
      get camera() {
        return { animateTo, animateToBounds };
      },
    }));

    return (
      <GoogleMap
        ref={mapRef}
        google={google}
        initialCenter={initialCenter}
        zoom={initialZoom || 5}
        onReady={onReady}
        disableDefaultUI
      >
        {children}
      </GoogleMap>
    );
  }
);

Map.displayName = 'Map';

export default Map;
